import React from "react";
import Home from "./staticfiles/home";
import Maintain from "./staticfiles/maintainance/maintain";

function App() {
  return (
    <div>
      {/* <Home /> */}
      <Maintain />
    </div>
  );
}

export default App;
