import React from "react";
import Gear from "../../images/gear.png";
import "../maintainance/maintain.css";
import Maintainpage from "../../images/maintain.png";

function Maintain() {
  return (
    <div className="text-center maintainance">
      <img className="gear-icon image" src={Gear} width={100} />
      <p>Site is under Maintenance</p>
      <img className="image maintain-image" src={Maintainpage} width={600} />
    </div>
  );
}

export default Maintain;
