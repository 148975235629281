import React from "react";
import Main from "./openpage/main";
import Featuredlearning from "./featured/featuredlearning";
import News from "./newsletter/news";
import Brand from "./branding/brand";
import Footer from "./footer/footer";

function Home() {
  return (
    <div>
      <Main />
      <Featuredlearning />
      <News />
      <Brand />
      <Footer />
    </div>
  );
}

export default Home;
